import http from '../utils/axios'

// 产品详情
export const getProDetail = prams => http('POST', '/product/detail', prams)

// 产品详情
export const updateProduct = prams => http('POST', '/product/update', prams)

// 用户管理
export const getUserPage = prams => http('POST', '/user/page', prams)

// 用户详情
export const getUserDetail = prams => http('POST', '/user/detail', prams)

// 解密用户
export const decryptUser = prams => http('POST', '/decryption', prams)


// 订单管理
export const getOrderPage = prams => http('POST', '/order/page', prams)

// 1：逾期手动扣款
export const repayOverdue = prams => http('POST', '/job/repayment/overdue', prams)

// 2：还款日当天还款
export const currentDayRepay = prams => http('POST', '/job/repayment', prams)

// 按照还款天数查询
export const filterOrderPage = prams => http('POST', '/order/repayInfo/page', prams)

// 重新打款
export const repayOrder = prams => http('POST', '/order/repayOrder', prams)

// 订单取消
export const cancelOrder = prams => http('POST', '/order/cancel', prams)

// 订单详情
export const getOrderDetail = prams => http('POST', '/order/detail', prams)

// 打款订单
export const getRemitPage = prams => http('POST', '/order/remit/page', prams)

// 还款订单
export const getRepayPage = prams => http('POST', '/order/repay/page', prams)


// 短信模板列表
export const getSmsTemplateList = prams => http('GET', '/sms/template/page', prams)

// 短信模板详情
export const getSmsTemplateDetail = prams => http('GET', '/sms/template/detail', prams)

// 新怎短信模板
export const addSmsTemplate = prams => http('POST', '/sms/template/add', prams)

// 编辑短信模板
export const editSmsTemplate = prams => http('POST', '/sms/template/edit', prams)

// 删除短信模板
export const delSmsTemplate = prams => http('POST', '/sms/template/del', prams)

// 短信记录
export const getSmsRecord = prams => http('GET', '/sms/record/page', prams)

// 短信渠道列表
export const getSmsChannel = prams => http('GET', '/sms/channel/page', prams)

// 短信渠道详情
export const getSmsChannelDetail = prams => http('GET', '/sms/channel/detail', prams)

// 添加短信渠道
export const addSmsChannel = prams => http('POST', '/sms/channel/add', prams)

// 编辑短信渠道
export const editSmsChannel = prams => http('POST', '/sms/channel/edit', prams)

// 删除短信渠道
export const delSmsChannel = prams => http('POST', '/sms/channel/del', prams)

// 系统资源配置
export const getResConfigPage = prams => http('GET', '/resource/config/page', prams)

// 获取系统资源部详情
export const getResConfigDetail = prams => http('GET', '/resource/config/detail', prams)

// 新增系统资源配置
export const addResConfig = prams => http('POST', '/resource/config/add', prams)

// 编辑系统资源配置
export const editResConfig = prams => http('POST', '/resource/config/edit', prams)

// 删除系统资源配置
export const delResConfig = prams => http('POST', '/resource/config/del', prams)

// 三方资源配置列表
export const getThirdConfigPage = prams => http('GET', '/third/party/config/page', prams)

// 三方资源配置详情
export const getThirdConfigDetail = prams => http('GET', '/third/party/config/detail', prams)

// 新增三方资源配置
export const addThirdConfig = prams => http('POST', '/third/party/config/add', prams)

// 编辑资源配置
export const editThirdConfig = prams => http('POST', '/third/party/config/edit', prams)

// 删除三方配置
export const delThirdConfig = prams => http('POST', '/third/party/config/del', prams)

// 定时任务列表
export const getJobConfigList = prams => http('GET', '/job/config/page', prams)

// 定时任务详情
export const getJobConfigDetail = prams => http('GET', '/job/config/detail', prams)

// 新增定时任务配置
export const addJobConfig = prams => http('POST', '/job/config/add', prams)

// 编辑定时任务
export const editJobConfig = prams => http('POST', '/job/config/edit', prams)

// 定时任务操作日志
export const getJobLog = prams => http('GET', '/job/log/page', prams)


// 1：财务对账
export const getOrderStatisticalPage = prams => http('POST', '/orderStatistical/page', prams)

// 2：对账确认
export const doAccountCheck = prams => http('POST', '/orderStatistical/doAccountCheck', prams)

// 3：对账明细
export const getOrderStatisticalDetail = prams => http('POST', '/orderStatistical/order/detail', prams)

// 3：4：对账明细filterOrderPage
export const excelBuild = prams => http('POST', '/orderStatistical/order/detail/excelBuild', prams)

// 3：合同管理
export const getSignerPage = prams => http('POST', '/signer/page', prams)

// 3：财务管理-平账列表
export const getOrderEvenList = prams => http('POST', '/orderEven/page', prams)

// 3：财务管理-平账列表
export const addOrderEven = prams => http('POST', '/orderEven/add', prams)

// 3：财务管理-平账详情
export const getOrderEvenDetail = prams => http('POST', '/orderEven/detailed', prams)
