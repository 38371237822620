<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content" style="padding: 0 10px;">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="auto"
                    label-suffix=":"
            >
                <el-form-item label="通道编码" prop="smsChannelCode">
                    <el-input
                            v-model="detailForm.smsChannelCode"
                            placeholder="请输入通道编码"
                    />
                </el-form-item>
                <el-form-item label="通道名称" prop="smsChannelName">
                    <el-input
                            v-model="detailForm.smsChannelName"
                            placeholder="请输入通道名称"
                    />
                </el-form-item>
                <el-form-item label="短信类型编码" prop="smsTypeCode">
                    <el-input
                            v-model="detailForm.smsTypeCode"
                            placeholder="请输入短信类型编码"
                    />
                </el-form-item>
                <el-form-item label="是否短信通道方注册模板" prop="isChannelTemplate">
                    <el-radio-group v-model="detailForm.isChannelTemplate">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="短信类型名称" prop="smsTypeName">
                    <el-input
                            v-model="detailForm.smsTypeName"
                            placeholder="请输入短信类型名称"
                    />
                </el-form-item>

                <el-form-item label="通道配置" prop="bizConfig" required>

                        <el-input
                                type="textarea"
                                :rows="6"
                                size="mini"
                                style="width: 80%;"
                                v-model="detailForm.bizConfig"
                                placeholder="通道配置"
                        ></el-input>

                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input
                            v-model="detailForm.sort"
                            placeholder="请输入排序"
                    />
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="detailForm.status">
                        <el-option :value="true" label="启用"></el-option>
                        <el-option :value="false" label="禁用"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getSmsChannelDetail,addSmsChannel,editSmsChannel,
    } from "@/api/business";


    export default {
        data() {
            // var validateJSON = (rule, value, callback) => {
            //     if (value) {
            //         try {
            //             JSON.parse(value)
            //         } catch (err) {
            //             callback(new Error('通道配置JSON格式有误'))
            //         }
            //         callback()
            //     }else {
            //         callback(new Error('请完善通道配置'))
            //     }
            // }
            return {
                pageLoad: false,
                title: "新增短信渠道",
                id: null,
                detailForm: {
                    id: this.$route.query.id || null,
                    smsChannelCode: "",
                    smsChannelName: "",
                    isChannelTemplate: "",
                    smsTypeCode: "",
                    smsTypeName: "",
                    smsSign: false,
                    bizConfig: "",
                    status: false,
                    sort: null
                },
                notLimited: false,
                limitDisable: false,
                detailRules: {
                    smsChannelCode: [{required: true, message: "请输入通道编码"}],
                    smsChannelName: [{required: true, message: "请输入通道名称"}],
                    isChannelTemplate: [{required: true, message: "请选择否短信通道方注册模板", trigger: 'change'}],
                    smsTypeCode: [{required: true, message: "请输入短信类型编码"}],
                    smsTypeName: [{required: true, message: "请输入短信类型名称"}],
                    smsSign: [{required: true, message: "请输入短信签名"}],
                    bizConfig: [{required: true, message: "请输入通道配置"}],
                    sort: [{required: true, message: "请输入排序"}],
                },
                saveLoad: false,
            };
        },
        created() {
            if (this.$route.query.id) {
                this.title = '编辑短信渠道';
                this.getSmsChannelDetail();
            }
        },
        methods: {
            editTaskDict() {
                if (this.detailForm.bizConfig) {
                    let taskParm = JSON.parse(this.detailForm.bizConfig);
                    try {
                        this.detailForm.bizConfig = JSON.stringify(taskParm, null, 4)
                    } catch (err) {
                        console.log(err)
                    }
                }
            },
            setLimited(val) {
                this.notLimited = val;
                if (val) {
                    this.detailForm.limitTime = 0;
                    this.limitDisable = true
                } else {
                    this.detailForm.limitTime = null;
                    this.limitDisable = false
                }
            },

            limitThree(name) {//正整数且只能输入3位
                this.detailForm[name] = this.detailForm[name].replace(/^(0+)|[^\d]+/g, '')
            },

            //新增模板
            addSmsChannel(form) {
                this.saveLoad = true;
                addSmsChannel(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //编辑模板
            editSmsChannel(form) {
                this.saveLoad = true;
                editSmsChannel(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getSmsChannelDetail() {
                this.pageLoad = true;
                getSmsChannelDetail({
                    id: this.detailForm.id
                }).then((res) => {
                    Object.assign(this.detailForm, res.data);
                    this.detailForm.bizConfig = JSON.stringify(JSON.parse(this.detailForm.bizConfig),null,4)
                    this.pageLoad = false;
                }).catch(() => {
                    this.pageLoad = false;
                });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        if (this.detailForm.id) {
                            this.editSmsChannel(this.detailForm)
                        } else {
                            this.addSmsChannel(this.detailForm)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/smsChannel");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
